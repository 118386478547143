/* eslint-disable no-underscore-dangle */

(function () { //eslint-disable-line strict

    if ('object' === typeof vf && 'object' === typeof vf.devhelper && 'function' === typeof vf.devhelper.serviceUrl) {
        serviceUrl = vf.devhelper.serviceUrl();
    } else {
        serviceUrl = '';
    }

    vf.wm = {
        settings: {
            target: '.mod-wm',
            service: {
                deviceguide: '../../assets/data/guides/{DeviceId}/',
                troubleshooting: '../../assets/data/troubleshooting/{DeviceId}/',
                wmapiurl: serviceUrl + '/api/help/'
            },
            html: {
                image: '<li class="wm-desc only-sml"><div class="wm-desc-box">{{stepDesc}}</div><div class="wm-on-step">Schritt {{index}} von {{count}}</div></li><li><div class="wm-helper" data-uuid="{{uuid}}" data-id="{{imageUrl}}"><img class="wm-pointer only-sml" src="{{pointerUrl}}" style="margin-top:{{positionTop}}px;margin-left:{{positionLeft}}px"/><img class="only-sml" style="margin-left:0px" src="{{imageUrl}}"/><img class="wm-pointer only-lrg" src="{{pointerUrl}}" style="margin-top:{{positionTop}}px;margin-left:{{positionLeft}}px"/><img class="only-lrg" src="{{imageUrl}}"/></div><div class="wm-on-step">Schritt {{index}} von {{count}}</div></li>',
                solution: '<div class="wm-step" data-step="{{step}}"><p>M&ouml;gliche Ursache {{step}} von {{length}}</p><h4>{{description}}</h4><p>{{Name}}</p><p>{{Description}}</p><p>{{Content}}</p><h4>{{guide.title}}</h4><p>{{guide.description}}</p>{{guide.content}}<div id="wm-images-{{DeviceId}}" class="di-content"></div></div>',
                solutionsuccess: '<p>Glad we could help.</p>',
                swipeNotification: '<div class="swipeNotification"><div class="swipeNotificationInner"><i class="i-swipe-w-mdm"></i><div class="h3">Swipe</div></div></div>'
            },
            animation: {
                pagescroll: 500
            },
            oFeedBackFlowModel: {
                sAuthToken: '',
                sWouldHaveContactedSupport: '',
                sFeedBackTarget: ''
            },



            /*
            authtokenUrlSEL: '/api/extAuthInt/oauth2/token',
            authtokenUrl: '/taf/fetchsendtoken?format=text',
            sendMailUrl: '/taf/sendmail',
            */
            authtokenUrl: 'https://opweb4.vfd2-testnet.de/taf/fetchsendtoken?format=text',
            sendMailUrl: 'https://opweb4.vfd2-testnet.de/taf/sendmail',
            ready: false,
            originalPageTitle: '',
            accessToken: false
        },
        init: function (context) {
            var s = vf.wm.settings,
                guideID = window.location.href.match(/guideID=([0-9]+)\&?/),
                solutionID = window.location.href.match(/solutionID=([0-9]+)\&?/);
            vf.wm.originalTitle = document.title;
            VF.platformType = 'responsive';
            VF.siteArea = 'service';
            $(s.target, context).addBack(s.target).each(function () {
                var wm = $(this);

                // Open toggles, GET JSON and display WM content
                wm.on('click', '.ac-head[data-type="WM FAQ"]', vf.wm.popguide);

                wm.on('click', '.ac-head[data-type="WM TS"]', vf.wm.popsolution);

                wm.on('click', '.ac-head[data-type="Standard"]', vf.wm.trackStaticContent);

                wm.on('click', '.ac-head[data-type="Video FAQ"]', vf.wm.trackStaticContent);
                wm.on('click', '.ac-head[data-type="Video FAQ"]', vf.wm.fixYoutubeIphoneBug);

                wm.children('li').on('click mouseover', '.pointer', vf.wm.toggleimage);

                wm.children('li').on('click', '.wm-yes, .wm-no', vf.wm.togglesolution);
                // wm.off('click', '.pointer', vf.wm.slider);

                wm.on('click', '.submit-feedback', $.proxy(vf.wm.submitFeedback, wm));

                wm.on('click', '.share-email', function (e) {
                    vf.wm.share(e, 'email');
                });

                wm.on('click', '.share-facebook', function (e) {
                    vf.wm.share(e, 'facebook');
                });

                wm.on('click', '.share-gplus', function (e) {
                    vf.wm.share(e, 'google+');
                });

                wm.on('click', '.share-twitter', function (e) {
                    vf.wm.share(e, 'twitter');
                });

                $('.youtube-play').on('click', function (e) {
                    vf.wm.trackVideo(e, 'Start');
                });

                // check for performance issues
                $(window).on('scroll', vf.wm.staticheader);
                
                
                // Help and support new contact module implemented in brix
                $(".brix-helpful__buttons").find(".brix-button").on('click', function (e) {
                    var parent = $(this).parents('li');
                    var headline = $.trim(parent.find('a.ac-head').text());
                    var uuid = $.trim(parent.find('a.ac-head').data('uuid'));
                    var type = $.trim(parent.find('a.ac-head').data('type'));
                    var homeLocation = Boolean($.trim(parent.find('a.ac-head').data('homeposition')));
                    var type = $.trim(parent.find('a.ac-head').data('type'));
                    window._ddq = window._ddq || [];
                    var selectedTarget = '';
                    if ($(this).closest('.brix-helpful__yes')[0]){
                        vf.wm.settings.oFeedBackFlowModel.sFeedBackTarget = 'JA';
                        selectedTarget = 'helpfull';
                    }
                    else {
                        vf.wm.settings.oFeedBackFlowModel.sFeedBackTarget = 'NEIN';
                        selectedTarget = 'not helpfull';
                    }
                    if(headline != ""){
                        window._ddq.push([
                            'H&S Article Action', {
                            Headline: headline,
                            ID: uuid,
                            Name: headline,
                            isHomeLocation: homeLocation,
                            Action: selectedTarget,
                            ContentType: type
                            }
                        ]);   
                    }
                    else {
                        window._ddq.push([
                            'page voter action', {
                                action: selectedTarget
                            }
                        ]);          
                    }
                });
    
                if ($(".brix-feedback__submit-btn")[0]){
            		var tokenURL = 'https://opweb4.vfd2-testnet.de/taf/fetchsendtoken?format=text';
                     $.ajax(tokenURL, {
                        dataType: 'text',
                        success: function (data) {
                            $('.brix-feedback').closest('[data-uuid]').attr('data-token', data);
                        }
                    });  
            	}

                $(".brix-feedback__submit-btn").find(".brix-button").on('click', function (e) {
                    var parent = $(this).parents('li');
                    var headline = $.trim(parent.find('a.ac-head').text());
                    var uuid = $.trim(parent.find('a.ac-head').data('uuid'));
                    var homeLocation = Boolean($.trim(parent.find('a.ac-head').data('homeposition')));
                    var type = $.trim(parent.find('a.ac-head').data('type'));
                    window._ddq = window._ddq || [];
                    if(headline != ""){
                        window._ddq.push([
                            'H&S Troubleshooter Action', {
                                Headline: headline,
                                ID: uuid,
                                Name: headline,
                                isHomeLocation: homeLocation,
                                Action: 'feedback submit',
                                ContentType: type
                            }
                        ]);
                    }
                    else {
                        window._ddq.push([
                            'page voter action', {
                                action: "feedback submit"
                            }
                        ]);          
                    }
                    var feedbackURL = 'https://opweb4.vfd2-testnet.de/taf/sendmail';
                    var notes='';
                    if(vf.wm.settings.oFeedBackFlowModel.sFeedBackTarget == "JA")
                        notes = $.trim(parent.find(".brix-helpful__yes-target").find(".brix-feedback__notes").val());
                    else if(vf.wm.settings.oFeedBackFlowModel.sFeedBackTarget == "NEIN")
                        notes = $.trim(parent.find(".brix-helpful__no-target").find(".brix-feedback__notes").val());

                    var feedbackData = {
                        efmessage: notes,
                        ctlfile: 'helpfeedback.ctl',
                        uuid: $(".brix-feedback").closest("[data-uuid]").data('uuid'),
                        step: 'kein Troubleshooter Artikel',
                        token: $(".brix-feedback").closest("[data-uuid]").data('token'),
                        url: window.location.href,
                        helpful: vf.wm.settings.oFeedBackFlowModel.sFeedBackTarget,
                        hilfreich: vf.wm.settings.oFeedBackFlowModel.sFeedBackTarget
                    };
                    $.ajax(feedbackURL, {
                        data: feedbackData,
                        type: 'post',
                        complete: function (){
                            vf.wm.settings.oFeedBackFlowModel.sFeedBackTarget = '';
                        }
                    });
                });

            });

            // Load guide or solution if in the URL
            if (-1 < window.location.href.indexOf('#')) {
                vf.wm.onpageload.call();
            } else {
                s.ready = true;
            }
            // This to handle anchors that needs to open another accordion in the same page
            // This just reads the href from the anchor and get the accordion title from it and triggers it's click event
            $(document).on('click', '.link-to-accordion', function (e) {
                e.preventDefault();
                var href = $(this).attr('href');
                var acTitle = (-1 < href.indexOf('#')) ? href.replace('#', '') : href;
                if (!$('.ac-head[id="' + acTitle + '"]').hasClass('ac-active')) {
                    // $('.ac-head[id="' + acTitle + '"]').addClass('ac-active');
                    $('.ac-head[id="' + acTitle + '"]').trigger('click');
                    // window.location.hash = href;
                }

            });

            //Detect window resize
            //This to fix the issue that when orientation change where wm-slider is opened, the dialog is still opened
            //and looks distorted (Check OT-1550)
            $(window).on('resize', vf.wm.windowResized);

            //fix defect with link in wm-tables
            $('.ac-body .wm-table a').on('click', function (e) {
                e.stopPropagation();
            });


        },
        onpageload: function (guideID, solutionID) {
            var s = vf.wm.settings,
                href = window.location.href;
            if (-1 < window.location.href.indexOf('#') && 0 < window.location.hash.length) {
                var acTitle = (-1 < href.indexOf('#')) ? href.split('#')[1] : '';
                head = $('.ac-head[id="' + acTitle + '"]');

                // On page load click guide or solution in URL
                if (0 < head.length) {
                    head.toggleClass('ac-active');
                    head.trigger('click');
                    //window._ddq = window._ddq || []; window._ddq.push(['h&s ready']);
                    head.next('.ac-body').show();

                    // On page load animate to first guide or solution
                    /*
                    setTimeout(function() {
                        var posY = head.offset().top -72 ;
                        //console.log('posY ' + posY);
                    $('html, body').animate({
                        scrollTop: posY
                    }, 'slow');
                    }, s.animation.pagescroll);
                    */
                }
            }
        },
        getHsInfoTracking: function(){
            var trackingDataElement = $('.wm-top-topics');
            // trackingDataElement.data('deviceid');
            // if its a device page
            if (trackingDataElement.data('devicetype') && trackingDataElement.data('manufacturer')) {
                VF.HSInfo = {
                    type: 'Devices Hub',
                    deviceType: trackingDataElement.data('devicetype'),
                    deviceBrand: trackingDataElement.data('manufacturer'),
                    deviceModel: trackingDataElement.data('devicemodel'),
                    deviceChapter: trackingDataElement.find('.wm-header h1').text(),
                    deviceSubChapter: trackingDataElement.find('.wm-header div.h2').text()
                };
            } else {
                VF.HSInfo = {
                    type: 'Themes FAQ',
                    // deviceType: trackingDataElement.data('devicetype'),
                    // deviceBrand: trackingDataElement.data('manufacturer'),
                    // deviceModel: trackingDataElement.data('devicemodel'),
                    themeChapter: trackingDataElement.find('.wm-header h1').text(),
                    themeSubChapter: trackingDataElement.find('.wm-header div.h2').text()
                };

            }
            return VF.HSInfo;
        },
        popguide: function (e) {
            var s = vf.wm.settings,
                guide = $(this).parent(),
                id = $(this).data('id'),
                images = '',
                deviceId = $(this).data('deviceid');

            if (deviceId && id) {

                $.ajax({
                    url: s.service.wmapiurl + 'troubleshooting/devices/' + deviceId + '/guides/' + id + '',
                    contentType: 'application/json',
                    dataType: 'json',
                    method: 'get',
                    headers: {
                        'Language': 'German',
                        'x-maxwidth': 310,
                        'x-maxheight': 450
                    }
                }).done( function (data) {
                    var content = '',
                        description = data.Description,
                        steps = 0;
                    //adding all blocks to content
                    $.each(data.Blocks, function (key, block) {
                        content += '<p class="bolded">' + block.Title + '</p>' + block.Content;
                    });
                    // Fetch content and load into guide
                    if (description && null !== description && 0 < description.length) {
                        description = '<p>' + description + '</p>';
                    } else {
                        description = '';
                    }
                    guide.find('.wm-content').html(description + content);
                    document.title = vf.wm.originalTitle;
                    //Track only on open
                    var target = $(e.target);

                    if ('a' !== e.target.localName) {
                        target = target.parents('li').find('a.ac-head');
                    }
                    if (target.parents('li').find('a.ac-head').hasClass('ac-active')) {
                        document.title = vf.wm.originalTitle + ' / ' + target.text();
                        if (!s.ready) {
                            VF.HSArticle = {
                                Headline: $.trim(target.text()),
                                ID: target.data('uuid'),
                                Name: $.trim(target.text()),
                                ContentType: target.data('type'),
                                isHomeLocation: Boolean(target.data('homeposition'))
                            };
                            s.ready = true;
                            // Set Data for tracking
                            vf.wm.getHsInfoTracking();
                            window._ddq = window._ddq || [];
                            window._ddq.push(['h&s ready']);
                        } else {
                            window._ddq = window._ddq || [];
                            window._ddq.push([
                                'H&S Article View', {
                                    Headline: $.trim(target.text()),
                                    ID: target.data('uuid'),
                                    Name: $.trim(target.text()),
                                    ContentType: target.data('type'),
                                    isHomeLocation: Boolean(target.data('homeposition'))
                                }
                            ]);
                        }
                    }

                    var pointers = guide.find('.pointer'),
                        count = pointers.length;

                    pointers.each(function (i) {
                        var pointer = $(this),
                            html = s.html.image,
                            data = $(this).data(),
                            dialog = guide.find('.di-content').attr('id');

                        var uuid = vf.wm.createUUID();
                        data.uuid = uuid;
                        $(this).attr('data-uuid', uuid);

                        // Build the HTML for the individual images
                        for (var prop in data) {
                            var re = new RegExp('{{' + prop + '}}', 'g');
                            html = html.replace(re, data[prop]);

                            // Add step ? of ?
                            html = html.replace('{{index}}', i + 1);
                            html = html.replace('{{count}}', count);
                        }
                        html = html.replace('{{stepDesc}}', pointer.parent().text());

                        images += html;

                        // Add dialog links to the pointers
                        $(this).attr('class', 'mod mod-dialog pointer').attr('data-href', '#' + dialog).attr('data-scope', 'sml');

                        // Move to the correct step
                        $(this).on('click', function () {
                            guide.find('.wm-slider').data('wm-slider').goToProp(i * 2);
                        });

                    });

                    // Bind dialog events to WM pointers
                    vf.util.initModules(guide.find( '.BlockList'));

                    // Write desktop images for desktop
                    guide.find('.wm-images').html('<ul class="only-lrg">' + images + '</ul>');

                    // Write dialog images
                    guide.find('.di-content').html('<div class="wm-slider">' + s.html.swipeNotification + '<ul>' + images + '</ul></div>');

                    // Start slider
                    vf.wm.slider.init(guide.find('.wm-slider'));

                }).fail(function (e) {
                    guide.find('.ac-body .white-box .alert').remove();
                    vf.alert.errorHandler(e, guide.find('.ac-body .white-box'));
                });
            }

        },
        popsolution: function (e) {
            var s = vf.wm.settings,
                guide = $(this).parent(), // TO DO var name misleading - copy-past job?
                id = $(this).data('id'),
                solutions = '',
                images = '',
                deviceId = $(this).data('deviceid');
            document.title = vf.wm.originalTitle;

            var target = $(e.target);
            if ('a' !== e.target.localName) {
                target = target.parents('li').find('a.ac-head');
            }
            //Track only on open
            if (target.hasClass('ac-active')) {
                document.title = vf.wm.originalTitle + ' / ' + target.text();
                if (!s.ready) {
                    VF.HSTroubleShooter = {
                        Headline: $.trim(target.text()),
                        ID: target.data('uuid'),
                        Topic: $.trim(target.text()),
                        isHomeLocation: Boolean(target.data('homeposition')),
                        Step: 1
                    };
                    // Set Data for tracking
                    vf.wm.getHsInfoTracking();
                    s.ready = true;
                    window._ddq = window._ddq || [];
                    window._ddq.push(['h&s ready']);
                } else {
                    window._ddq = window._ddq || [];
                    window._ddq.push([
                        'H&S Troubleshooter View', {
                            Headline: $.trim(target.text()),
                            ID: target.data('uuid'),
                            Topic: $.trim(target.text()),
                            isHomeLocation: Boolean(target.data('homeposition')),
                            Step: 1
                        }
                    ]);
                }
            }
            if (deviceId && id) {

                $.ajax(s.service.wmapiurl + 'troubleshooting/devices/' + deviceId + '/problems/' + id + '', {
                    contentType: 'application/json',
                    method: 'get',
                    headers: {
                        'Language': 'German',
                        'x-maxwidth': 310,
                        'x-maxheight': 450
                    },
                    dataType: 'json',
                    success: function (data) {
                        var content = data,
                            solution = content.Solutions,
                            solutionguide,
                            steps,
                            id = 'wm-images-' + guide.find('[data-id]').data('id');
                        if (1 < solution.length) {
                            var noNextSolutionText = (767 < $(window).width()) ? globalconfig.noNextSolutionButton : globalconfig.noNextSolutionButtonMobileView;
                            $(guide).find('.feedback-flow .wm-no').addClass('next-solution').html(noNextSolutionText);
                        }
                        $.each(solution, function (i) {

                            // Add the description and step numbers to the WM Object
                            solution[i].description = content.Description;
                            solution[i].step = i + 1;
                            solution[i].length = solution.length;

                            var html = s.html.solution;
                            for (var prop in solution[i]) {
                                var re = new RegExp('{{' + prop + '}}', 'g');
                                html = html.replace(re, solution[i][prop]);
                            }

                            if (solution[i].Guide) {
                                html = html.replace('{{guide.title}}', solution[i].Guide.Name);
                                html = html.replace('{{guide.description}}', solution[i].Guide.Description);
                                var guideContent = '';
                                $.each(solution[i].Guide.Blocks, function (key, block) {
                                    guideContent += '<p>' + block.Title + '</p>' + block.Content;
                                });
                                html = html.replace('{{guide.content}}', guideContent);
                            } else {
                                html = html.replace('{{guide.title}}', '');
                                html = html.replace('{{guide.description}}', '');
                                html = html.replace('{{guide.content}}', '');
                            }

                            solutions += html;

                        });

                        guide.find('.wm-images').html('<ul class="only-lrg">' + images + '</ul>');
                        // Write all solutions and staus messages to the page
                        $(guide).find('.wm-solutions').html(solutions)
                            .append('<div class="wm-step wm-status" data-step="-1"><p>' + content.TextWhenNotSolved + '</p></div>')
                            .append('<div class="wm-step wm-status" data-step="-2">' + s.html.solutionsuccess + '</div>');

                        //Add active class to first step
                        guide.find('.wm-step:first').addClass('active');
                        var imagesstore = '';

                        guide.find('.wm-step').not('.wm-status').each(function (i) {
                            var step = $(this),
                                pointers = step.find('.pointer'),
                                images = '',
                                _id = id + '-' + i,
                                count = pointers.length;

                            pointers.each(function (i) {
                                var pointer = $(this),
                                    s = vf.wm.settings,
                                    html = s.html.image,
                                    data = pointer.data();

                                var uuid = vf.wm.createUUID();
                                data.uuid = uuid;
                                $(this).attr('data-uuid', uuid);

                                // Build the HTML for the individual images
                                for (var prop in data) {
                                    var re = new RegExp('{{' + prop + '}}', 'g');
                                    html = html.replace(re, data[prop]);

                                    // Add step ? of ?
                                    html = html.replace('{{index}}', i + 1);
                                    html = html.replace('{{count}}', count);
                                }

                                html = html.replace('{{stepDesc}}', pointer.parent().text());

                                images += html;

                                // Add dialog links to the pointers
                                $(this).attr('class', 'mod mod-dialog pointer').attr('data-href', '#' + _id).attr('data-scope', 'sml');

                                // Move to the correct step
                                $(this).on('click', function () {
                                    step.find('.wm-slider').data('wm-slider').goToProp(i * 2);
                                });

                            });


                            imagesstore += images;

                            // Write images into step dialogs
                            step.find('.di-content').attr('id', _id).html('<div class="wm-slider">' + s.html.swipeNotification + '<ul>' + images + '</ul></div>');

                        });

                        // Bind dialog events to WM pointers
                        vf.util.initModules(guide.find('.BlockList'));

                        // Write desktop images for desktop
                        guide.find('.wm-images').html('<ul class="only-lrg">' + imagesstore + '</ul>');

                        // Bind slider events to slider instance
                        vf.wm.slider.init(guide.find('.wm-slider'));

                        //This was there to solve an issue with the wm slider but now after the slider is replaced this is commented
                        // guide.find('.wm-helper').each(function() {
                        //  if ($(this).find('img').not('.wm-pointer').width() == 320) {
                        //      $(this).find('img').not('.wm-pointer').addClass('wm-img-lrg ');
                        //  }
                        // });

                    }
                }).fail(function (e) {
                    guide.find('.ac-body .white-box .alert').remove();
                    vf.alert.errorHandler(e, guide.find('.ac-body .white-box'));
                });

            }

        },
        toggleimage: function (e) {
            e.preventDefault();
            var id = $(this).data('uuid'),
                images = $(e.delegateTarget).find('.wm-images');

            // Hide all images
            images.find('.wm-helper').hide();

            // Show step image
            images.find('.wm-helper[data-uuid="' + id + '"]').show();
            vf.wm.fixPosition($(e.currentTarget));

        },
        addAuthToken: function (el) {
            // vf always gives you a ref to the root
            var s = vf.wm.settings;

            //sends a request to the authtoken Url
            // plain text is exspected as a result
            // when succeeded the data the element "el" is given a data  which is a autheticate token attribute containing the data
            $.ajax(s.authtokenUrl, {
                dataType: 'text',
                success: function (data) {
                    el.attr('data-token', data);
                    vf.wm.settings.oFeedBackFlowModel.sAuthToken = data;
                    return data;
                }
            });
        },
        /**
        /* Triggered when "could you problem been solved" is clicked - either yes or no
        /* the yes or no value is extraced form the css class name
        /* @param {object} e - click event
        */
        togglesolution: function (e) {
            e.preventDefault();
            var step = $(e.delegateTarget).find('.wm-step');
            //only change images if there are more steps
            if (0 < step.length) {
                vf.wm.toggleimage(e);
            }
            var el = $(this);

            var parent = $(e.target).parents('li');
            var headline = $.trim(parent.find('a.ac-head').text());
            var uuid = $.trim(parent.find('a.ac-head').data('uuid'));
            var homeLocation = Boolean($.trim(parent.find('a.ac-head').data('homeposition')));
            var type = $.trim(parent.find('a.ac-head').data('type'));

            // if yes was clicked
            if ($(this).hasClass('wm-yes')) {
                vf.wm.feedbackMain(el, 'yes');
                // IF Popsoulution with more then one step push  helpfull
                if (0 < step.length) {
                    $(step).each(function () {
                        if ($(this).is(':visible') && 'static' === $(this).css('position').toLowerCase()) {
                            var actstep = $(this).data('step');
                            //Track only on open
                            if ($(parent.find('a.ac-head')).hasClass('ac-active')) {
                                window._ddq = window._ddq || [];
                                window._ddq.push([
                                    'H&S Troubleshooter Action', {
                                        Headline: headline,
                                        ID: uuid,
                                        Topic: headline,
                                        isHomeLocation: homeLocation,
                                        Action: 'helpfull',
                                        Step: actstep
                                    }
                                ]);
                            }
                        }
                    });
                } else {
                    //Track only on open
                    if ($(e.target).parents('li').find('a.ac-head').hasClass('ac-active')) {
                        window._ddq = window._ddq || [];
                        window._ddq.push([
                            'H&S Article Action', {
                                Headline: headline,
                                ID: uuid,
                                Name: headline,
                                isHomeLocation: homeLocation,
                                Action: 'helpfull',
                                ContentType: type
                            }
                        ]);
                    }
                }
            }
            // if NO was clicked
            else {
                if (0 < step.length) {
                    //Remove active class from all items
                    $(step).removeClass('active');
                    $(step).each(function () {

                        if ($(this).is(':visible') && 'static' === $(this).css('position').toLowerCase()) {

                            var nextstep = $(this).data('step') + 1;
                            nextstep = step.filter('[data-step=' + nextstep + ']');
                            //Set active step
                            nextstep.addClass('active');
                            //only click first pointer on large screens to show images
                            if ('lrg' === vf.util.layout()) {
                                nextstep.find('.pointer:first').trigger('click');
                            }
                            //Track only on open
                            if ($(parent.find('a.ac-head')).hasClass('ac-active')) {
                                window._ddq = window._ddq || [];
                                window._ddq.push([
                                    'H&S Troubleshooter Action', {
                                        Headline: headline,
                                        ID: uuid,
                                        Topic: headline,
                                        isHomeLocation: homeLocation,
                                        Action: 'not helpfull',
                                        Step: (step.not('.wm-status').length === $(this).data('step')) ? 'last' : $(this).data('step')
                                    }
                                ]);
                            }
                            if (0 < nextstep.length) {
                                step.css({
                                    position: 'absolute',
                                    left: '-999em'
                                });
                                // Show next step
                                $(el).closest('.feedback-flow').removeClass('feedback-active').removeClass('feedback-yes').removeClass('feedback-no');
                                $(el).closest('.ac-body').find('.feedback-message').remove();
                                //step.hide();
                                //nextstep.show();
                                // Show next step
                                nextstep.css('position', 'static');
                                window._ddq = window._ddq || [];
                                window._ddq.push([
                                    'H&S Troubleshooter View', {
                                        Headline: headline,
                                        ID: uuid,
                                        Topic: headline,
                                        isHomeLocation: homeLocation,
                                        Step: (step.not('.wm-status').length === $(this).data('step') + 1) ? 'last' : $(this).data('step') + 1
                                    }
                                ]);
                                if (0 === nextstep.next('.wm-step:not(".wm-status")').length) {
                                    nextstep.closest('.ac-body').find('.feedback-flow .wm-no').removeClass('next-solution').text(globalconfig.noButton);
                                }

                                $('html, body').animate({
                                    scrollTop: ($(el).closest('.ac-body').prev('.ac-head').offset().top - 10)
                                }, 'slow');
                                //dirty hack to avoid no button being focused / active
                                //copying old button and insert it. after that, delete original button...
                                $(el).after($(el).clone(true));
                                $(el).remove();
                            } else {
                                $(this).addClass('active');
                                // No more steps. Show WM failure message.
                                //step.filter('[data-step="-1"]').css('position', 'static').closest('li').find('.wm-images').hide();
                                vf.wm.feedbackMain(el, 'no');
                            }

                            // Exit loop with correct step visible
                            return false;
                        }
                    });

                } else { //If there's only one step which happens in guides
                    //step.filter('[data-step="-1"]').css('position', 'static').closest('li').find('.wm-images').hide();
                    //Track only on open
                    if ($(e.target).parents('li').find('a.ac-head').hasClass('ac-active')) {
                        window._ddq = window._ddq || [];
                        window._ddq.push([
                            'H&S Article Action', {
                                Headline: headline,
                                ID: uuid,
                                Name: headline,
                                isHomeLocation: homeLocation,
                                Action: 'not helpfull',
                                ContentType: type

                            }
                        ]);
                    }
                    vf.wm.feedbackMain(el, 'no');
                }

            }
        },
        /**
        * triggered when yes no button is clicked
        * @param {object} el - button context
        * @param {string} feedbackTarget - indicates if yes or no has been clicked
        */
        feedbackMain: function (el, feedbackTarget) {

            vf.wm.settings.oFeedBackFlowModel.sFeedBackTarget = feedbackTarget;

            // feedback flow is the div that holds the yes and no buttons
            // now accesible in the element container
            var elementContainer = $(el).closest('.feedback-flow');
            //var guid = elementContainer.data('guid');

            // do some ui changes
            elementContainer.removeClass('feedback-active').removeClass('feedback-yes').removeClass('feedback-no').addClass('feedback-active').addClass('feedback-' + feedbackTarget.toLowerCase());
            $(elementContainer).closest('.ac-body').find('.feedback-message').remove();

            // disable the buttons
            /*
            $(el).addClass( "btn-disabled" );
            $(el).css( "color", "#ccc" );
            if (feedbackTarget == "no"){
                $(el).prev().addClass( "btn-disabled" );
            }else{
                $(el).next().addClass( "btn-disabled" );
            }
            */

            // check if yes or no has been clicked
            if ('yes' === feedbackTarget){
                this.drawWouldContactedSupportUI(el);
                //                this.drawDefaultForm(el, 'yes');
            }
            else {
                this.drawDefaultForm(el, 'no');
                $('.feedback-main').addClass('access');
                $('.feedback-message').prepend($('#feedback-notHelpfull').render());
                $('.feedback-notHelpfull input').on('change', function(e){
                    $('.question').text($(this).data('question'));
                    $('.feedback-main').removeClass('access');
                    vf.wm.trackNotHelpful(e);

                });
            }

            vf.form.init();
            // the token is attached to the
            // vf.wm.addAuthToken($(elementContainer).closest('.ac-body').find('.feedback-message').find('.submit-feedback'));
            vf.wm.addAuthToken($(elementContainer));




        },
        /**
        * draws the devault view;
        * @param {object} el - original button context
        * @param {string} feedbackTarget - either "yes" or "no"
        */
        drawDefaultForm: function (el, feedbackTarget)
        {
            var elementContainer = $(el).closest('.feedback-flow');
            // this is undefined !
            var guid = elementContainer.data('guid');

            $(elementContainer).after('<div class=\'feedback-message\'>' + $('#feedback-main').render({
                itemGuid: guid,
                feedbackTarget: feedbackTarget
            }) + '<div>');
        },
        /**
        * creates a div that shows the the "would you have contacted support" question
        * @param {object} el - original button context
        */
        drawWouldContactedSupportUI: function (el)
        {
            var elementContainer = $(el).closest('.feedback-flow');

            var oDivTag = $('<div class=\'feedback-message wouldContactedSupportUI\'>' +
                '<p style="margin-bottom:0px"><b>W&auml;re sonst ein Anruf oder eine E-Mail an uns n&ouml;tig gewesen?</b></p>' + '<br>' +
                '<a href="#" class="wm-yes2 btn btn-alt btn-sml" style="min-width:80px; margin-right:12px">Ja</a>' +
                '<a href="#" class="wm-no2 btn btn-alt btn-sml" style="min-width:80px;"">Nein</a>' +
                '<div>');
            $(elementContainer).after(oDivTag);

            $('.wm-yes2').on('click', el, vf.wm.wouldHaveContactedSupportHandler);
            $('.wm-no2').on('click', el, vf.wm.wouldHaveContactedSupportHandler);
        },
        /**
        * handles yes no answer and records Tealium actuib
        * @param {object} e - the click event
        */
        wouldHaveContactedSupportHandler: function (e)
        {
            e.preventDefault();

            function addWouldHaveContactedSupportToTealium(sYesNo, e){
                var parent = $(e.currentTarget).parents('li');
                var headline = $.trim(parent.find('a.ac-head').text());
                var uuid = $.trim(parent.find('a.ac-head').data('uuid'));
                var type = $.trim(parent.find('a.ac-head').data('type'));
                var homeLocation = Boolean($.trim(parent.find('a.ac-head').data('homeposition')));
                _ddq.push(
                    [
                        'H&S Article Action',
                        {
                            'Headline': headline,
                            'ID': uuid,
                            'Name': headline, // ueberschrift check
                            'isHomeLocation': homeLocation,
                            'Action': sYesNo,
                            'ContentType': type
                        }
                    ]
                );
            }

            if ($(this).hasClass('wm-yes2')) {
                vf.wm.settings.oFeedBackFlowModel.sWouldHaveContactedSupport = 'yes';
                addWouldHaveContactedSupportToTealium('contact yes', e);
            }
            else {
                vf.wm.settings.oFeedBackFlowModel.sWouldHaveContactedSupport = 'no';
                addWouldHaveContactedSupportToTealium('contact no', e);
            }

            // remove the drawWouldContactedSupportUI
            $('.wouldContactedSupportUI').remove();

            // draw the feedback form
            vf.wm.drawDefaultForm(e.data, 'yes');
        },

        drawNotHelpfullHandler: function(){

        },
        trackNotHelpful: function(e){
            var answer = $(e.currentTarget).next('label').text();
            var action = $(e.currentTarget).data('action');
            var parent = $(e.currentTarget).parents('li');
            var headline = $.trim(parent.find('a.ac-head').text());
            var uuid = $.trim(parent.find('a.ac-head').data('uuid'));
            var type = $.trim(parent.find('a.ac-head').data('type'));
            var homeLocation = Boolean($.trim(parent.find('a.ac-head').data('homeposition')));
            _ddq.push(
                [
                    'H&S Article Action',
                    {
                        'Headline': headline,
                        'ID': uuid,
                        'Name': headline,
                        'isHomeLocation': homeLocation,
                        'Action': action,
                        'ContentType': type,
                        'Answer': answer
                    }
                ]
            );
        },
        /**
        * handels the feedback form submit
        * @param {object} e - the click event
        */
        submitFeedback: function (e) {
            e.preventDefault();
            var s = vf.wm.settings;
            vf.wm.share(e, 'feedback submit');
            var form = $(e.currentTarget).closest('form');

            if (0 === form.find('.fm-error').length && 0 === form.find('.fm-error-border').length) {

                var feedbackTarget = vf.wm.settings.oFeedBackFlowModel.sFeedBackTarget;
                var wouldHaveContactedSupport = vf.wm.settings.oFeedBackFlowModel.sWouldHaveContactedSupport;

                var data = {
                    efmessage: form.find('#ef-message').val(),
                    ctlfile: 'helpfeedback.ctl',
                    uuid: form.parents('li').find('a.ac-active').data('uuid'),
                    step: 'kein Troubleshooter Artikel',
                    //token: form.find('.submit-feedback').data('token'),
                    token: vf.wm.settings.oFeedBackFlowModel.sAuthToken,
                    url: window.location.href,
                    // add the "hilfreich" value
                    hilfreich: ('yes' === feedbackTarget) ? 'ja' : 'nein'
                    // TODO: the "haetten die den support kotaktiert" value must be added here as well!!!!!!


                };
                if ($(e.currentTarget).parents('.ac-body').find('.wm-step.active').data('step')) {
                    data.step = $(e.currentTarget).parents('.ac-body').find('.wm-step.active').data('step');
                }
                // do the actual request
                $.ajax(s.sendMailUrl, {
                    data: data,
                    type: 'post',
                    complete: function (){
                        vf.wm.settings.oFeedBackFlowModel.sFeedBackTarget = '';
                        vf.wm.settings.oFeedBackFlowModel.sWouldHaveContactedSupport = '';
                        vf.wm.settings.oFeedBackFlowModel.sAuthToken = '';
                    }
                });
                var elementContainer = $(e.target).closest('.feedback-message');

                if ('yes' === feedbackTarget) {
                    elementContainer.html($('#feedback-yes').render());
                    elementContainer.find('.social-list-container').empty().append($('#social-sharing-list-main').html());
                    $('#shareEmailForm').html('<iframe src="https://' + window.location.hostname + '/taf/start?url=' + escape(document.location) + '" width="100%" height="100%"></iframe>');
                    vf.util.initModules('.feedback-message');
                } else if ('no' === feedbackTarget) {
                    elementContainer.html($('#feedback-no').render());
                }
            }
        },
        staticheader: function (e) {
            var currentpos = $(window).scrollTop(),
                body = $('.mod-wm .ac-body:visible');


            body.each(function (i) {

                var top = $(this).position().top,
                    bottom = $(this).offset().top + $(this).height(),
                    header = $(this).find('.wm-static-header');

                header.removeClass('wm-sh-show');

                // If required, this will only show the static header on an up scroll
                // if (currentpos >= vf.wm.position) return;

                if (($(window).scrollTop() > top + 10) && $(window).scrollTop() < bottom) {
                    header.addClass('wm-sh-show');
                }


            });

            vf.wm.position = (currentpos);

        },
        fixYoutubeIphoneBug: function(e){
            var element = $(e.currentTarget);
            if (element.hasClass('ac-active')){
                element.next('.ac-body').find('.white-box .youtube').html('');
                vf.util.initModules(element.next('.ac-body').find('.white-box'));
            }
        },
        trackVideo: function (e, action) {
            var parent = $(e.currentTarget).parents('li');
            var uuid = $.trim(parent.find('a.ac-head').data('uuid'));
            window._ddq = window._ddq || [];
            window._ddq.push([
                'Video Tracking', {
                    'Article ID': uuid,
                    'VideoName': $(e.currentTarget).parent().attr('id'),
                    'Action': action
                }
            ]);

        },
        trackStaticContent: function (e) {
            var s = vf.wm.settings;
            var target = $(e.target);
            if ('a' !== e.target.localName) {
                target = target.parents('li').find('a.ac-head');
            }
            if (target.hasClass('ac-active')) {

                if (!s.ready) {
                    VF.HSArticle = {
                        Headline: $.trim(target.text()),
                        ID: target.data('uuid'),
                        Name: $.trim(target.text()),
                        ContentType: target.data('type'),
                        isHomeLocation: Boolean(target.data('homeposition'))
                    };
                    // Set Data for tracking
                    vf.wm.getHsInfoTracking();
                    s.ready = true;
                    window._ddq = window._ddq || [];
                    window._ddq.push([
                        'H&S Article View', {
                            Headline: $.trim(target.text()),
                            ID: target.data('uuid'),
                            Name: $.trim(target.text()),
                            ContentType: target.data('type'),
                            isHomeLocation: Boolean(target.data('homeposition'))
                        }
                    ]);
                    //window._ddq.push(['h&s ready']);
                } else {
                    window._ddq = window._ddq || [];
                    window._ddq.push([
                        'H&S Article View', {
                            Headline: $.trim(target.text()),
                            ID: target.data('uuid'),
                            Name: $.trim(target.text()),
                            ContentType: target.data('type'),
                            isHomeLocation: Boolean(target.data('homeposition'))
                        }
                    ]);
                }
            }
        },
        share: function (e, action) {
            var parent = $(e.currentTarget).parents('li');
            var headline = $.trim(parent.find('a.ac-head').text());
            var uuid = $.trim(parent.find('a.ac-head').data('uuid'));
            var type = $.trim(parent.find('a.ac-head').data('type'));
            var step = $.trim(parent.find('.active').data('step'));
            var homeLocation = Boolean($.trim(parent.find('a.ac-head').data('homeposition')));
            //if there are steps -> Troubleshooter
            if (0 < parent.find('.wm-step').length) {
                window._ddq = window._ddq || [];
                window._ddq.push([
                    'H&S Troubleshooter Action', {
                        Headline: headline,
                        ID: uuid,
                        Topic: headline,
                        isHomeLocation: homeLocation,
                        Action: action,
                        Step: (parent.find('.wm-step').not('.wm-status').length === step) ? 'last' : step
                    }
                ]);
            } else {
                window._ddq = window._ddq || [];
                window._ddq.push([
                    'H&S Article Action', {
                        Headline: headline,
                        ID: uuid,
                        Name: headline,
                        isHomeLocation: homeLocation,
                        Action: action,
                        ContentType: type
                    }
                ]);
            }
        },
        fixPosition: function (elem) {

            var imageContainer = $('a.ac-active').next('.ac-body').find('.wm-images ul');
            var contentContainer = $('a.ac-active').next('.ac-body').find('.wm-content');

            if ( 0 < imageContainer.children().length && 450 < contentContainer.height()){
                imageContainer.css('margin-top', elem.position().top - imageContainer.position().top);
            }
        },
        windowResized: function () {
            var windowWidth = $(window).width();
            var isDesktop = (767 < windowWidth);
            if (isDesktop && $('[id^=wm-images-]').hasClass('di-show')) {
                $('.di-close').trigger('click');
            }

            if (isDesktop) {
                var noNextSolutionText = globalconfig.noNextSolutionButton;
            } else {
                var noNextSolutionText = globalconfig.noNextSolutionButtonMobileView;
            }
            $('.wm-no.next-solution').html(noNextSolutionText);
        },
        createUUID: function () {
            // http://www.ietf.org/rfc/rfc4122.txt
            var s = [];
            var hexDigits = '0123456789abcdef';
            for (var i = 0; 36 > i; i++) {
                s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
            }
            s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
            s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
            s[8] = s[13] = s[18] = s[23] = '-';

            var uuid = s.join('');
            return uuid;
        }

    };
    
}(vf));

/* eslint-enable no-underscore-dangle */
